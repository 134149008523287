const state = () => ({
  notif: []
})

const mutations = {
  setNotif (state, notif) {
    state.notif = notif
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}