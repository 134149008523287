const state = () => ({
  token: null,
  user: {},
  fcm: null
})

const mutations = {
  setToken (state, token) {
    state.token = token
  },
  setUser (state, user) {
    state.user = user
  },
  setFcm (state, fcm) {
    state.fcm = fcm
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}