import Vue from "vue"
import * as VueGoogleMaps from "vue2-google-maps"

Vue.use(VueGoogleMaps, {
	load: {
		key: "AIzaSyCpSBm0ehBiEr4muaISG3Rgm_UNR6Bfd5M",
		libraries: "places,geometry",
		region: "ID",
		language: "id",
		v: '3.26'
	},
  installComponents: true
})

export default VueGoogleMaps